/***** Dataiku Brand Color *****/
/***** Universe color *****/
/***** Primary action colors *****/
/***** Greyscale *****/
/***** Status colors *****/
/******* Chart color *******/
/******* Colors per object types *******/
/******* Custom colors for plugin recipes *******/
/******* Variables helpers *******/
/****** Components *******/
/****** Buttons *******/
.unavailable-result {
  height: 100%;
  width: 100%;
  background-image: repeating-linear-gradient(-45deg, #F2F2F2 0px, #F2F2F2 5px, #DDDDDD 6px, #DDDDDD 11px);
  opacity: 0.6;
}
