@import 'fonts-variables.less';

@font-face {
    font-family : SourceSansPro;
    src : url(/static/dataiku/fonts/SourceSansPro-Bold.woff);
    font-weight: @font-weight-bold;
}

@font-face {
    font-family : SourceSansPro;
    src : url(/static/dataiku/fonts/SourceSansPro-Semibold.woff);
    font-weight: @font-weight-semi-bold;
}

@font-face {
    font-family : SourceSansPro;
    src : url(/static/dataiku/fonts/SourceSansPro-Regular.woff);
    font-weight: @font-weight-normal;
}

.mx-ssp(@size, @weight) {
    font-family : 'SourceSansPro';
    font-weight: @weight;
    font-size: @size;
}

.mx-rightcol-title-font {
    .mx-ssp(13px, @font-weight-bold);
}

.font-weight-normal {
    font-weight: @font-weight-normal;
}

.font-weight-semi-bold {
    font-weight: @font-weight-semi-bold;
}

.font-weight-bold {
    font-weight: @font-weight-bold;
}
